<template>
    <div class="container mb-5">

        <h2 class="head2 mb-5">{{Lang.get('link5')}}</h2>
        <div class="text-red bold Truculenta text-37" style="max-width:850px;margin:auto;" v-html="Lang.get('text5')"></div>
        <div>

        </div>
        <div v-if="Lang.SelectedLanguage=='tr'" class="mt-5">
            <img src="../assets/map-tesisler.svg" class="img-fluid" style="max-width:600px;"/>
        </div>
        <div v-if="Lang.SelectedLanguage=='en'" class="mt-5">
            <img src="../assets/map-tesislerEN.svg" class="img-fluid" style="max-width:600px;"/>
        </div>
        <div v-if="Lang.SelectedLanguage=='tr'">
            <div class="row">
                <div class="col-12 col-md-6">
                    <center>
                        <img src="../assets/facility-1.png" class="img-fluid" />
                    </center>

                </div>
                <div class="col-12 col-md-6">
                    <center>
                        <img src="../assets/facility-2.png" class="img-fluid" />
                    </center>

                </div>
            </div>

        </div>
        <div v-if="Lang.SelectedLanguage=='en'">
            <div class="row">
                <div class="col-12 col-md-6">
                    <center>
                        <img src="../assets/fac1.png" class="img-fluid" />
                    </center>

                </div>
                <div class="col-12 col-md-6">
                    <center>
                        <img src="../assets/fac2.png" class="img-fluid" />
                    </center>
                </div>
            </div>
        </div>
        <router-link :to="'/'+Lang.SelectedLanguage" class="backhome"> {{Lang.get('backhome')}} </router-link>
        <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/Ülker Bisküvi Üretim Tesisleri.pdf" class="download">{{Lang.get('download')}}</a>
        <a v-if="Lang.SelectedLanguage=='en'" target="_blank" href="/files/Ülker Bisküvi Production Facilities.pdf" class="download">{{Lang.get('download')}}</a>
    </div>




</template>

<script>
    import lng from "../store/languages";

    export default {
        name: 'Chairman',
        components: {},
        data() {
            return {
                Lang: lng,
            }
        }
    }</script>
